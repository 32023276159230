// These values come from an Agency's parser
// and MUST mean the same across different agencies
// or else lead to great confusion
//
// We may want to consider refactoring this to a number input
// If it gets too out of hand...

export const maxRVLengthOptions = [
  {
    value: "18", // BCParks/OntarioParks
    label: "Up to 18'",
  },
  {
    value: "20", // AlbertaParks
    label: "Up to 20'",
  },
  {
    value: "21", // ParksCanada
    label: "Up to 21'",
  },
  {
    value: "24", // ParksCanada
    label: "Up to 24'",
  },
  {
    value: "25", // OntarioParks
    label: "Up to 25'",
  },
  {
    value: "27", // ParksCanada
    label: "Up to 27'",
  },
  {
    value: "30", // ParksCanada/AlbertaParks
    label: "Up to 30'",
  },
  {
    value: "32", // BCParks/OntarioParks
    label: "Up to 32'",
  },
  {
    value: "33", // BCParks/OntarioParks
    label: "Over 32'",
  },
  {
    value: "35", // ParksCanada
    label: "Up to 35'",
  },
  {
    value: "36", // ParksCanada
    label: "Over 35'",
  },
  {
    value: "40", // AlbertaParks
    label: "Up to 40'",
  },
  {
    value: "50", // AlbertaParks
    label: "Up to 50'",
  },
  {
    value: "60", // AlbertaParks
    label: "Up to 60'",
  },
  {
    value: "61", // AlbertaParks
    label: "Over 60'",
  },
];
