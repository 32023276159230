import { formatAvailabilityRequestDate } from "./dates.js";
import { maybePluralizeNoun } from "./words.js";
import { sortedSiteTypes } from "./maps/siteTypes.js";

function includeNightsText(availabilityRequest) {
  // If this gets updated, make sure to update the arguments
  // that are passed in via RequestForm
  // (it doesn't use the whole availabilityRequest object)
  return availabilityRequest.siteType !== "other";
}

function nightsText(availabilityRequest) {
  const nights = availabilityRequest.minStayLength;
  return maybePluralizeNoun(nights, "night");
}

function sitesText(
  availabilityRequest,
  { count, includeSiteType = false } = {},
) {
  const sites =
    typeof count === "number" ? count : availabilityRequest._count.sites;

  if (!includeSiteType) {
    return maybePluralizeNoun(sites, "site");
  }

  const siteType = sortedSiteTypes.find(
    (siteType) => siteType.value === availabilityRequest.siteType,
  );

  if (!siteType) {
    return maybePluralizeNoun(sites, "site");
  }

  if (siteType.value !== "other") {
    return maybePluralizeNoun(sites, `${siteType.label} site`);
  }

  return maybePluralizeNoun(sites, "site");
}

function arrivalText(availabilityRequest) {
  const dates = availabilityRequest.selectedDates;
  const maxFullDates = 2;

  const fullDates = dates.slice(0, maxFullDates).map((date) => {
    const day = formatAvailabilityRequestDate(date, "M/dd");
    const dayOfWeek = formatAvailabilityRequestDate(date, "E");
    return `${dayOfWeek} ${day}`;
  });

  let moreText = "";
  if (dates.length > maxFullDates) {
    const remainingDates = dates.length - maxFullDates;
    moreText = `, and ${remainingDates} more`;
  }

  return `${fullDates.join(", ")}${moreText}`;
}

function includeCapacityText(availabilityRequest) {
  return availabilityRequest.minCapacity > 1;
}

function capacityText(availabilityRequest) {
  const capacity = availabilityRequest.minCapacity;

  // TODO: Get a dynamic label
  // If we add it to site's extraAttributes,
  // We'll have to pass the sites in here somehow
  // Alternatively, we could just put it on the facility

  return capacity === 1 ? "1 person" : `${capacity} people`;
}

export {
  includeNightsText,
  includeCapacityText,
  nightsText,
  sitesText,
  arrivalText,
  capacityText,
};
