import {
  parse,
  format,
  parseISO,
  formatISO,
  isBefore,
  set,
  addDays,
  getUnixTime,
} from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";

const AVAILABILITY_REQUEST_FORMAT_STRING = "yyyyMMdd";
const FACILITY_FORMAT_STRING = "yyyy-MM-dd";

function parseFacilityDate(date) {
  return parse(date, FACILITY_FORMAT_STRING, new Date());
}

function formatFacilityDate(date, formatString = "yyyy-MM-dd") {
  return format(parseFacilityDate(date), formatString);
}

function parseAvailabilityRequestDate(date) {
  return parse(date.toString(), AVAILABILITY_REQUEST_FORMAT_STRING, new Date());
}

function formatAvailabilityRequestDate(date, formatString = "yyyy-MM-dd") {
  return format(parseAvailabilityRequestDate(date), formatString);
}

function formatToAvailabilityRequestDate(date) {
  return parseInt(format(date, AVAILABILITY_REQUEST_FORMAT_STRING, new Date()));
}

function toUnixTimestamp(date) {
  return Math.floor(date.getTime() / 1000);
}

function transformDateString(
  dateString,
  transformer,
  formatString = "yyyy-MM-dd",
) {
  return format(
    transformer(parse(dateString, formatString, new Date())),
    formatString,
  );
}

function transformISODateString(dateString, transformer) {
  return formatISO(transformer(parseISO(dateString)));
}

// !ChatGPT wrote this
function setTimeToUnix613pm(date = new Date(), timeZone = "America/Edmonton") {
  const localTime = toZonedTime(date, timeZone);
  const targetTime = set(localTime, {
    hours: 18,
    minutes: 13,
    seconds: 0,
    milliseconds: 0,
  });

  let resultDate;
  if (isBefore(localTime, targetTime)) {
    resultDate = targetTime;
  } else {
    resultDate = addDays(targetTime, 1);
  }

  // Convert the local time back to UTC
  const utcResultDate = fromZonedTime(resultDate, timeZone);

  // Convert the UTC date to a Unix timestamp
  return getUnixTime(utcResultDate);
}

export {
  AVAILABILITY_REQUEST_FORMAT_STRING,
  FACILITY_FORMAT_STRING,
  parseFacilityDate,
  formatFacilityDate,
  parseAvailabilityRequestDate,
  formatAvailabilityRequestDate,
  formatToAvailabilityRequestDate,
  toUnixTimestamp,
  transformDateString,
  transformISODateString,
  setTimeToUnix613pm,
};
