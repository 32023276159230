const sortedSiteTypes = [
  { value: "rv", label: "RV" },
  { value: "tent", label: "Tent" },
  // While site_type rv_tent does technically still exist,
  // It's not a valid client-side filter anymore
  //
  // Since we now select only either 'rv' or 'tent' on the client side
  // and merge rv_tent sites into each of the options
  //
  // This makes it easier for rv users to select against all sites
  // in one go, without having to select 'rv or tent' and 'rv only'
  //
  // { value: "rv_tent", label: "RV or Tent" },
  { value: "backcountry", label: "Backcountry" },
  { value: "lodging", label: "Lodging" },
  { value: "group", label: "Group" },
  { value: "other", label: "Other" },
];

export { sortedSiteTypes };
